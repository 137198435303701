import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/lanoisearray',
    name: 'lanoisearray',
    // redirect: '/home',
    // component: Home
    component: () => import(/* webpackChunkName: "about" */ '../components/LanoisearrayView.vue')
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },{
    path: '/firstmiles',
    name: 'firstmiles',
    // redirect: '/home',
    // component: Home
    component: () => import(/* webpackChunkName: "about" */ '../components/FirstmilesView.vue')
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },{
    path: '/tstation',
    name: 'tstation',
    // redirect: '/home',
    // component: Home
    component: () => import(/* webpackChunkName: "about" */ '../components/TstationView.vue')
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },{
    path: '/remembrance',
    name: 'remembrance',
    // redirect: '/home',
    // component: Home
    component: () => import(/* webpackChunkName: "about" */ '../components/RemembranceView.vue')
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },{
    path: '/audiovisual',
    name: 'audiovisual',
    component: () => import(/* webpackChunkName: "about" */ '../components/AudiovisualView.vue')
  },{
    path: '/conceptmaps',
    name: 'conceptmaps',
    component: () => import(/* webpackChunkName: "about" */ '../components/ConceptmapsView.vue')
  },{
    path: '/grandjunction',
    name: 'grandjunction',
    component: () => import(/* webpackChunkName: "about" */ '../components/GandjunctionView.vue')
  },{
    path: '/landparcels',
    name: 'landparcels',
    component: () => import(/* webpackChunkName: "about" */ '../components/LandparcelsView.vue')
  },{
    path: '/indicators',
    name: 'indicators',
    component: () => import(/* webpackChunkName: "about" */ '../components/IndicatorsView.vue')
  },{
    path: '/atlas',
    name: 'atlas',
    // redirect: '/atlas',
    // component: atlas
    component: () => import(/* webpackChunkName: "atlas" */ '../components/AtlasoflightingView.vue')
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },{
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
