<template>
  <div class="ui grid home">
<!--    <div class="twelve column row">-->
<!--      <div class="six wide computer five wide tablet four wide mobile column"> </div>-->
<!--      <div class="six wide computer eleven wide tablet twelve wide mobile column about">-->
<!--        <p>-->
<!--          {{p1}}-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->


    <div class="three column row">
      <div class="four wide computer two wide tablet one wide mobile column"> </div>
      <div class="two wide computer three wide mobile column category-header">
        PORTFOLIO
<!--        <router-link v-bind:mode="mode" to="/home">PROJECTS</router-link>-->
      </div>
      <div class="four wide computer eleven wide tablet twelve wide mobile column">
      </div>
    </div>
<!--    </transition>-->
<!--    <transition name="component-fade" mode="in">-->
    <div class="twelve column row">
      <div class="six wide computer five wide tablet four wide mobile column"> </div>
      <div class="six wide computer eleven wide tablet twelve wide mobile column">
      <p>
        <router-link v-bind:mode="mode" to="/firstmiles">FIRST MILES</router-link>
      </p>
      <p>
        <router-link v-bind:mode="mode" to="/grandjunction">GRAND JUNCTION PATHWAY SCENARIO MODELING</router-link>
      </p>
    <p>
      <router-link v-bind:mode="mode" to="/lanoisearray">LA NOISE ARRAY</router-link>
    </p>
      <p>
        <router-link to="/atlas">ATLAS OF LIGHTING</router-link>
      </p>
    <p>
      <router-link to="/tstation">TSTATION.INFO</router-link>
    </p>
      <p>
        <router-link to="/remembrance">REMEMBRANCE OF THE CLIMATE FUTURES</router-link>
      </p>
      <p>
        <router-link to="/landparcels">MA LAND PARCELS</router-link>
      </p>
      <p>
        <router-link to="/indicators">TRANSPORTATION INDICATORS</router-link>
      </p>
      </div>
    </div>

    <div class="three column row">
      <div class="four wide computer two wide tablet one wide mobile column"> </div>
      <div class="two wide computer three wide mobile column category-header">
        PUBLICATIONS
<!--        <router-link v-bind:mode="mode" to="/home">PUBLICATIONS</router-link>-->
      </div>
      <div class="four wide computer eleven wide tablet twelve wide mobile column">
      </div>
    </div>

    <div class="twelve column row">
      <div class="six wide computer five wide tablet four wide mobile column"> </div>
      <div class="six wide computer eleven wide tablet twelve wide mobile column publications ">
        <p>
          Small, M.L., Akhavan, A., Torres, M. et al. Banks, alternative institutions and the spatial–temporal ecology of racial inequality in US cities. Nat Hum Behav (2021).<a href="https://doi.org/10.1038/s41562-021-01153-1"  target="_blank"> https://doi.org/10.1038/s41562-021-01153-1</a>
        </p>
        <p>
          Gehrke, S. R., Akhavan, A., Furth, P. G., Wang, Q., & Reardon, T. G. (2020). A cycling-focused accessibility tool to support regional bike network connectivity. Transportation Research Part D, 85, 102388. <a href="https://doi.org/10.1016/j.trd.2020.102388"  target="_blank">https://doi.org/10.1016/j.trd.2020.102388.
          </a>
        </p>
        <p>
          A Akhavan, NE Phillips, J Du, J Chen, B Sadeghinasr, Q Wang, 2018. Accessibility Inequality in Houston. IEEE Sensors Letters 3 (1), 1-4.  <a href="https://ieeexplore.ieee.org/document/8543173"  target="_blank">10.1109/LSENS.2018.2882806.
        </a>
        </p>
      </div>
    </div>



    <div class="three column row">
      <div class="four wide computer two wide tablet one wide mobile column"> </div>
      <div class="two wide computer three wide mobile column category-header">
        OTHER
<!--        <router-link to="/HOME">DESIGN SCHOOL WORKS</router-link>-->
      </div>
      <div class="four wide computer eleven wide tablet twelve wide mobile column">
      </div>
    </div>
    <div class="twelve column row">
      <div class="six wide computer five wide tablet four wide mobile column"> </div>
      <div class="six wide computer eleven wide tablet twelve wide mobile column">
        <p>
          <router-link to="/conceptmaps">CONCEPT MAPS</router-link>
        </p>
        <p>
          <router-link to="/audiovisual">AUDIO VISUAL TRANSPARENCY</router-link>
        </p>
      </div>
    </div>




    <div class="three column row">
      <div class="four wide computer two wide tablet one wide mobile column"> </div>
      <div class="two wide computer three wide mobile column category-header">
        <div class="about-header">
<!--        <router-link to="/about">ABOUT</router-link>-->
<!--        {{p1}}-->
    </div>
      </div>
<!--      <div class="six wide computer eleven wide tablet twelve wide mobile column"><div class="about"><p>{{p1}}</p>  </div> </div>-->
    </div>



  </div>
</template>
<script>

import 'semantic-ui-css/semantic.min.css'
// @ is an alias to /src
import LanoisearrayView from '@/components/LanoisearrayView.vue'
export default {
  name: 'home',
  components: {
    LanoisearrayView
  },
  data: function(){
    return {
    mode:"home",
      p1:
          "I am a designer, developer, engineer, policy analyst and city planner using IT-enabled-interdisciplinary design methods to produce knowledge to be used" +
          " as arguments for planning and policy making. "
  }
  },
}
</script>
<style scoped>
  .ui.grid>.row {
    padding-bottom: 0px;
  }
.home {
  -moz-transform:  translate(-7vw, 1vh) skew(0deg, -12deg);
  -webkit-transform:  translate(-7vw, 1vh) skew(0deg, -12deg);
  -o-transform:  translate(-7vw, 1vh) skew(0deg, -12deg);
  -ms-transform: translate(-7vw, 1vh) skew(0deg, -12deg);
  transform:  translate(-7vw, 5vh) skew(0deg, -10deg);
    margin-left: 5%;
    margin-right: 5%;
}
.home p{
  line-height: 1;
  text-align: left;
}
.about p{
  line-height: 1.4em;
  text-align: left;
  font-size: smaller;
}
  .category-header {
   text-align: right;transform:  translate(-1vw, -1vh) skew(0deg, 25deg);
  }


  .publications p {
    font-size: smaller;
    line-height: 1.2em;
  }
</style>