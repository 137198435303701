<template>
    <transition name="component-fade" mode="out-in">
<div class="ui grid">
    <router-link to="/home"  id="homelink" class="home">{{mode}}</router-link>
<div class="two column row" id="lanoisearray">
    <div class="fourteen wide computer fourteen wide tablet fourteen wide mobile column">
    <img src="@/assets/images/ln1.png">
    </div>
</div>
    <div class="two column row">
    <div class="eight wide computer fourteen wide tablet fourteen wide mobile column">
    <h4>​Urban Soundscape Pilot</h4>
    <h4>For Northeastern University – Information Design + Visualization</h4>
    <p>As visualization specialist and web developer</p>
    <p>
        Live: <a href="http://noisearray.northeastern.edu/" target="_blank">
        noisearray.northeastern.edu
        </a>
    </p>
        <p>{{p1}}</p>
        <p>{{p2}}</p>
    </div>
<div class="six wide computer fourteen wide tablet column">
    <img src="@/assets/images/ln2.png">
    <img src="@/assets/images/ln3.png">
    <img src="@/assets/images/ln4.jpeg">
</div>
</div>
    <div class="one column row">
        <div class="seven wide computer fourteen wide tablet column">
            <img src="@/assets/images/ln5.jpeg">
        </div>
        <div class="seven wide computer fourteen wide tablet column">
            <img src="@/assets/images/ln6.jpeg">
            <img src="@/assets/images/ln7.png">
        </div>
    </div>
    <div class="one column row">
        <div class="seven wide computer fourteen wide tablet column"></div>
        <iframe width=80% height="515" src="https://www.youtube.com/embed/7b-Ccdfgw7Y" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
</div>
    </transition>
</template>
<script>
export default {
    name: 'LanosearrayView',
    data() {
        return {
            p1: "Visualizing soundscape of urban environment for a " +
                "pilot project for Philips Lighting LA, " +
                "this project is a multi-platform web application, " +
                "which is built using R, R shiny-server, and Threejs, and Nodejs. \n" +
                "Taking the R visuals already made with a variety of R " +
                "packages including ggplot and leaflet, I refactored the R " +
                "code into an interactive shiny web application. I also developed a " +
                "prototype application for visualizing the same dataset, but using " +
                "Threejs, enabling to view the sensors reading data on street level.",
            p2: "We were also interested to see the sensors' data on a map. However, " +
                "when visualizing a dataset on a map, since the graphical elements such" +
                " as line, area, and color are already used up for spatial relations, . \n" +
                " adding visual variables for the sound data is challenging, considering" +
                " the continuous nature of sound data, we need to also add the time as " +
                "a dimension. This being said, I started experimenting with libraries \n " +
                "and packages such as Mapzen's Tangram, Mapbox WebGL, etc. However, I " +
                "ended up using Threejs and making the 3D scene from scratch."
        };
    },
    computed:{
        mode: function () {
            let self = this;
            return self.$parent.mode
        }

    },

    mounted() {
        // const targets = this.$el;
        // this
        // .$anime
        // .timeline()
        // .set({
        //     opacity:0
        // })
        // .add({
        //     targets,
        //     translateX: 240,
        //     delay: 600,
        //     endDelay: 400,
        //     easing: 'easeInOutQuad',
        //     update: function(anim) {
        //         // // progressLogEl.value = 'progress : ' + Math.round(anim.progress) + '%';
        //         // beginLogEl.value = 'began : ' + anim.began;
        //         // completeLogEl.value = 'completed : ' + anim.completed;
        //     },
        //     begin: function(anim) {
        //         // beginLogEl.value = 'began : ' + anim.began;
        //     },
        //     complete: function(anim) {
        //         // completeLogEl.value = 'completed : ' + anim.completed;
        //     }
        // })
        // .add({
        //     targets,
        //     // opacity:0,
        //     // translateX:50,
        //     translateY:150,
        //     easing: 'easeInOutQuad',
        // })
        
        /* ... etc ... */
    }
}
</script>
<style scoped>
/*img {*/
/*    margin-top: 40px;*/
/*        !*width: 60%;*!*/
/*    !*margin-left: 20%;*!*/
/*    !*margin-right: 10%;*!*/
/*    }*/
#lanoisearray {
  /*-moz-transform: rotate(344deg) translate(-50px, 28px) skew(-5deg, 7deg);*/
  /*  -webkit-transform: rotate(344deg) translate(-50px, 28px) skew(-5deg, 7deg);*/
  /*  -o-transform: rotate(344deg) translate(-50px, 28px) skew(-5deg, 7deg);*/
  /*  -ms-transform: rotate(344deg) translate(-50px, 28px) skew(-5deg, 7deg);*/
  /*  transform: rotate(344deg) translate(-50px, 28px) skew(-5deg, 7deg);*/
  /*  left: -100;*/

    /*margin-left: 20%;*/
    /*margin-right: 10%;*/
    /*width: 60%;*/
}


</style>