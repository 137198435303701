<template>
  <div id="app" class="app">
    <div class="one column row">
<!--    <div id="nav">-->
      <p>
        <transition name="component-fade" mode="out-in">

        </transition>
      </p>
<!--        <p><router-link to="/lanoisearray">LA Noise Array</router-link>-->
<!--        </p>-->
          <!-- <router-link to="/about">About</router-link> -->
<!--    </div>-->

    <main class="ui grid container">

      <transition name="component-fade" mode="out-in">
      <router-view/>
      </transition>
    </main>
    </div>
  </div>
</template>
<script>import 'semantic-ui-css/semantic.min.css'

export default {
    name: "App",
    data: function(){return {
      mode:"ARMIN AKHAVAN"
    }
    },
    computed:{

    },
    methods: {

     }}

</script>
<style>

.app p {
  padding-top: 7px;
  padding-left: 15px;


}
  p {line-height: 2em;
  }


body {
        background: rgba(0,0,0,.99);
        color: #fff;
        font-family: monospace;
  font-size: medium;
  margin-left: 10%;
  margin-right: 5%;

}
code {
  color: red;
  font-size: small;
}


.component-fade-enter {
  transition: all .1s ease;
  animation: bounce-in .1s;
  
}
.component-fade-leave {
  
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  animation: bounce-in .1s reverse;
}
.component-fade-enter-active {
  
  transition: all .1s ease;
  animation: bounce-in .1s;
}
.component-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  animation: bounce-in .1s reverse;
}
.component-fade-enter, .component-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  /* transform: translateX(10px); */
  opacity: 0;
}
@keyframes bounce-in {
  0% {
    transform: rotate(1deg);
    opacity: .04;
  }
  50% {
    transform: rotate(120deg);
    opacity: .10;
  }
  100% {
    transform: rotate(330deg);
    opacity: 0.30;
  }
}

</style>
