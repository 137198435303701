import { render, staticRenderFns } from "./App.vue?vue&type=template&id=29f68fd1"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=29f68fd1&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_2aqslel7prtkk4onzp5d3vudie/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports