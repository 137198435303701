import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAnime from 'vue-animejs';
import SuiVue from 'semantic-ui-vue'
import VueExpandableImage from 'vue-expandable-image'
import VueGtag from "vue-gtag";

Vue.use(VueExpandableImage)
Vue.use(VueAnime)
Vue.use(SuiVue)
Vue.use(VueGtag, {
  config: { id: "G-5ECSEBW8KV" }
});
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
